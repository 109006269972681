import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  // hide modal
  // action: "turbo-modal#hideModal"
  hideModal() {
    const loginFrame = document.getElementById('loginModal');
    loginFrame.removeAttribute('src');

    const modal = document.getElementById('sign-in-modal');
    modal.classList.remove('is-active');
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code === 'Escape') {
      const modal = document.getElementById('sign-in-modal');
      modal.classList.remove('is-active');
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    const loginBackground = document.getElementById('modal-background');
    if (e && e.target === loginBackground) {
      const modal = document.getElementById('sign-in-modal');
      modal.classList.remove('is-active');
    }
  }
}
