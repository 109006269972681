import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="flash-messages"
export default class extends Controller {
  close() {
    const flashMessage = document.getElementById('note');
    if (flashMessage) {
      flashMessage.style.display = 'none';
    }
  }
}
