// search_news_events_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  getFilteredNewsEvents(event) {
    const form = event.currentTarget;
    const url = form.action;

    event.preventDefault();
    fetch(url + '?search=' + event.target.value, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'text/vnd.turbo-stream.html',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); // Parse the response as text
      })
      .then((data) => {
        document.getElementById('contentNewsEvents').innerHTML = '<div id="news_events_cards"></div>';
        document.getElementById('news_events_cards').innerHTML = data;
      })
      .catch((error) => {
        console.error('Error fetching Turbo Stream data:', error);
      });
  }
}
