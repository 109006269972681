import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleButton', 'menu'];

  setIconDisplay() {
    const icon = this.toggleButtonTarget.querySelector('i.fa-bars');
    if (icon) {
      icon.style.display = this.menuTarget.classList.contains('hide') ? '' : 'none';
    }
  }

  closeMenu() {
    this.menuTarget.classList.add('hide');
    this.setIconDisplay();
  }

  toggleMenu() {
    this.menuTarget.classList.toggle('hide');
    this.setIconDisplay();
  }
}
