import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggleModal() {
    const modalPreview = document.createElement('div');
    modalPreview.className = 'modal fade';
    modalPreview.id = 'modal-preview';
    document.getElementById('modal-preview').replaceWith(modalPreview);
  }
}
