import { Controller } from '@hotwired/stimulus';
import { createApp } from 'vue';
import NewsCarousel from '../components/home/news-carousel.vue';

const components = { NewsCarousel };

export default class extends Controller {
  connect() {
    this.mountVueComponents();
    document.addEventListener('turbo:frame-load', this.mountVueComponents);
  }

  disconnect() {
    document.removeEventListener('turbo:frame-load', this.mountVueComponents);
    this.turboFrameListenerAdded = false;
  }

  mountVueComponents = () => {
    document.querySelectorAll('[data-vue-component]').forEach((element) => {
      if (element.__vue__) {
        return;
      }

      const componentName = element.getAttribute('data-vue-component');
      const component = components[componentName];

      if (component) {
        const props = JSON.parse(element.getAttribute('data') || '{}');
        createApp(component, props).mount(element);
        element.__vue__ = true;
      }
    });
  };
}
