import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  setDateTime(event) {
    if (event.target.value === 'true') {
      const date = new Date();

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;
      document.getElementById(event.currentTarget.getAttribute('data-id')).value = formattedDate;
    } else {
      document.getElementById(event.currentTarget.getAttribute('data-id')).value = event.target.value;
    }
  }
}
