import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const headingElements = document.querySelectorAll('.job-applications-collapsible-heading');
    const tabElement = document.querySelector('.job-applications-collapsible-tab');
    const firstTab = headingElements[0].parentElement;

    headingElements.forEach((heading) => {
      heading.addEventListener('click', function () {
        heading.classList.toggle('active-heading');
        heading.nextElementSibling.classList.toggle('show');
        heading.getElementsByTagName('img')[0].classList.toggle('open');
        tabElement.classList.toggle('filterExpand');
        tabElement.classList.toggle('filterContract');
      });
    });

    const parentElement = this.element;
    const errorElements = parentElement.querySelectorAll('.form-input__box--errors');

    if (errorElements.length !== 0) {
      firstTab.querySelector('.job-applications-collapsible-heading').classList.remove('active-heading');
      firstTab.querySelector('.job-applications-collapsible-content').classList.remove('show');
      firstTab.getElementsByTagName('img')[0].classList.toggle('open');
    }

    errorElements.forEach((errorElement) => {
      const collapsibleTabElement = errorElement.closest('.job-applications-collapsible-tab');
      if (collapsibleTabElement) {
        const headingElement = collapsibleTabElement.querySelector('.job-applications-collapsible-heading');
        const contentElement = collapsibleTabElement.querySelector('.job-applications-collapsible-content');
        if (headingElement && contentElement) {
          headingElement.classList.add('has-text-danger', 'active-heading');
          headingElement.getElementsByTagName('img')[0].classList.add('open', 'edit_profile_arrow_error');
          contentElement.classList.add('show');
        }
      }
    });
  }
}
