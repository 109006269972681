<template>
  <div class="container news">
    <div class="card-footer carousel--news__footer">
      <ol class="pagination">
        <li v-for="(card, index) in cards" :key="card.id">
          <a :class="{ active: isActive(index) }" @click="skipTo(index)"></a>
        </li>
      </ol>
    </div>
    <div v-if="cards.length > 0" class="is-flex third" @mouseover="pause" @mouseleave="resume">
      <div id="text" class="column carousel--news__overlay">
        <div class="card-content carousel--news__content">
          <a :href="cards[index].url">
            <p class="subtitle subtitle--light">{{ cards[index].title }}</p>
            <p class="subtitle__description subtitle__description--light subtitle__description--bold">
              {{ cards[index].subtitle }}
            </p>
          </a>
          <div class="description-news">
            <a :href="cards[index].url">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p class="description-block description-block--light news-description" v-html="cards[index].description"></p>
            </a>
          </div>
        </div>
      </div>
      <img id="image" class="column carousel--news__tile" :src="cards[index]?.image_url" :alt="cards[index]?.image_description" />
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  data() {
    return {
      autoscroll: false,
      index: 0,
      cards: [],
      textElement: null,
      imageElement: null,
      imageHeight: 0,
    };
  },
  mounted() {
    if (document.getElementById('carousels') !== null) {
      const dataElement = document.getElementById('carousels').dataset.news;
      const cards = JSON.parse(dataElement);
      if (cards.length > 0) {
        this.cards = cards;
        this.autoscroll = true;
        this.scroll();
      }
    }

    this.$nextTick(() => {
      this.textElement = document.getElementById('text');
      this.imageElement = document.getElementById('image');
      this.imageElement.addEventListener('load', () => {
        this.resizeContent();
      });
    });
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    isActive(index) {
      if (index === this.index) {
        return true;
      }
    },
    next() {
      this.textElement = document.getElementById('text');
      this.imageElement = document.getElementById('image');
      this.resizeContent();
      if (this.index === this.cards.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
    },
    scroll() {
      setInterval(() => {
        if (this.autoscroll) {
          this.next();
        }
      }, 5500);
    },
    pause() {
      this.autoscroll = false;
    },
    resume() {
      this.autoscroll = true;
    },
    skipTo(card) {
      this.index = card;
      this.textElement = document.getElementById('text');
      this.imageElement = document.getElementById('image');

      this.resizeContent();
    },
    resizeHandler() {
      this.textElement = document.getElementById('text');
      this.imageElement = document.getElementById('image');
      this.resizeContent();
    },
    resizeContent() {
      if (this.textElement && this.imageElement) {
        this.imageHeight = window.getComputedStyle(this.imageElement).getPropertyValue('height');
        this.textElement.style.height = this.imageHeight;
      }
    },
    formatDate(timestamp) {
      return format(new Date(timestamp), 'MMM dd, yyyy @ hh:mm a');
    },
  },
};
</script>
