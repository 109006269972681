import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['articleType'];

  connect() {
    this.toggleDateTimeFields();
  }

  toggleDateTimeFields() {
    const selectedType = this.articleTypeTarget.value;
    const dateTimeFields = document.getElementById('date_time_fields');

    if (selectedType === 'Event') {
      dateTimeFields.style.display = 'block';
    } else {
      dateTimeFields.style.display = 'none';
    }
  }

  change() {
    this.toggleDateTimeFields();
  }
}
