import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['changePassword', 'changeEmail'];
  toggle(event) {
    event.preventDefault();
    if (event.target.id === 'password_change_button') {
      this.changePasswordTargets.forEach((s) => {
        s.classList.toggle('hidden');
      });
      this.changeEmailTargets.forEach((s) => {
        s.classList.add('hidden');
      });
      const passwordButton = document.getElementById('password_change_button');
      const passwordIcon = document.getElementById('password_change_icon');

      passwordButton.classList.toggle('is-danger');
      passwordButton.classList.toggle('is-light');
      passwordIcon.classList.toggle('fa-lock');
      passwordIcon.classList.toggle('fa-unlock-alt');
    } else if (event.target.id === 'email_change_button') {
      this.changeEmailTargets.forEach((s) => {
        s.classList.toggle('hidden');
      });
      this.changePasswordTargets.forEach((s) => {
        s.classList.add('hidden');
      });
      // const emailButton = document.getElementById('email_change_button');
      // const emailIcon = document.getElementById('email_change_icon');

      // emailButton.classList.toggle('is-danger');
      // emailButton.classList.toggle('is-light');
      // emailIcon.classList.toggle('fa-envelope');
      // emailIcon.classList.toggle('fa-envelope-open');
    }
  }
}
