import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  close() {
    const modalMessage = document.getElementById('dormancy-modal');
    if (modalMessage) {
      modalMessage.style.display = 'none';
    }
  }
}
