import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['navbar'];

  connect() {
    window.addEventListener('scroll', this.checkScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener('scroll', this.checkScroll.bind(this));
  }

  checkScroll() {
    if (window.scrollY > 100) {
      this.navbarTarget.classList.remove('hide');
    } else {
      this.navbarTarget.classList.add('hide');
    }
  }
}
